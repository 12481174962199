import { Rate } from 'antd'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import { DoctorImage as _DoctorImage, DoctorImgWrapper as _DoctorImgWrapper } from '../../../components/Cards'
import { GridItem, GridLayout } from '../../../components/Grid'
import Tags from '../../../components/Tags'
import { space } from '../../../constants/length'
import { Primary, White } from '../../../constants/newColor'
import { H2, H4, Tag, Body as _Body } from '../../../constants/newText'
import { viewport } from '../../../constants/viewport'
import { Location } from '../../../types/types'
import { homePageMaxWidth } from '../ClinicSection'

const Wrapper = styled.div`
  max-width: ${homePageMaxWidth * 0.8}px;
  margin: 0 auto;
  @media (min-width: ${viewport.tablet}px) {
    padding-right: ${space.xxl * 3}px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (min-width: ${viewport.tablet}px) {
    padding-top: 8%;
    width: 328px;
    margin: 0 auto;
    flex-direction: column;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    padding-top: 0;
    width: 90%;
    margin: 0 auto ${space.xxl}px auto;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const DoctorImgWrapper = styled(_DoctorImgWrapper)`
  transform: scale(1.3);
  @media (max-width: ${viewport.tablet - 1}px) {
    transform: scale(1.1);
    transform-origin: top right;
  }
`

const DoctorImage = styled(_DoctorImage)`
  transform: scale(1.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-left: 0;
  }
`

const NameAndTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${viewport.tablet}px) {
    align-items: center;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    align-items: flex-start;
    margin-left: ${space.l}px;
    margin-top: ${space.m * 5}px;
  }
`

const Name = styled.h3`
${H2}
  color: ${Primary};
  text-align: left;
  padding-right: ${space.m}px;
  margin-top: 40px;
  margin-bottom: 8px;
  span {
    font-size: 14px;
    margin-right: 8px;
  }

  @media(max-width: ${viewport.tablet - 1}px){
    margin-top: 0;
  }
`

const Title = styled.div`
  ${Tag}
  position: absolute;
  top: 3%;
  left: 67%;
  border: 1px solid ${Primary};
  color: ${Primary};
  background-color: ${White};
  padding: 4px;
  text-align: center;
  box-shadow: 3px -3.5px 0 black;
  @media (max-width: ${viewport.tablet - 1}px) {
    top: 30%;
    left: ${space.l}px;
    transform: translate(0, -50%);
  }
`

const ItemTitle = styled.h4`
  ${H4}
  margin-bottom: ${space.s}px;
`
const Body = styled.p`
  ${_Body}
  text-align: left;
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 80%;
  }
`

const CommentTitle = styled.h4`
  ${H4}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${space.s}px;

  :before,
  :after {
    content: '';
    display: block;
    width: 10%;
    height: 1px;
    background-color: ${Primary};
    margin: 0 8px;
  }
`

const CommentText = styled.div`
  ${_Body}
  border: 1px solid ${Primary};
  border-radius: 8px;
  box-shadow: 0 3.5px 0 black;
  padding: 16px;
  text-align: left;
  margin-bottom: ${space.s}px;

  display: flex;
  flex-direction: column;
`

interface DoctorProps {
  doctorImageUrl?: string
  title?: string
  name: string
  location: Location[]
  strength?: string
  commend?: string
  active?: boolean
  handleToggle?: () => void
  handleRWD?: () => void
}

const Doctor = (props: DoctorProps) => {
  const { doctorImageUrl, title, name, location, strength, commend } = props
  const { formatMessage } = useIntl()

  return (
    <Wrapper>
      <GridLayout templateColumns={['100%', '1fr 1fr']} columnGap={['unset', '24px']}>
        <InfoWrapper>
          <DoctorImgWrapper>
            <DoctorImage src={doctorImageUrl} alt={`菁英醫師-${name}`} />
          </DoctorImgWrapper>
          <Title>{title}</Title>
          <NameAndTagsWrapper>
            <Name>
              <span>Dr.</span>
              {name}
            </Name>
            <TagList>
              {location.map(clinic => (
                <Tags m='4px' key={clinic} type={clinic} title={formatMessage({ id: `location.${clinic}` })} />
              ))}
            </TagList>
          </NameAndTagsWrapper>
        </InfoWrapper>
        <ContentWrapper>
          <GridLayout templateColumns={['100%']} rowGap={['36px', '24px']}>
            <GridItem>
              <ItemTitle>主治專長</ItemTitle>
              <Body>{strength}</Body>
            </GridItem>
            <GridItem>
              <CommentTitle>患者好評</CommentTitle>
              <CommentText>
                “ {commend} ”
                <Rate disabled defaultValue={5} />
              </CommentText>
            </GridItem>
          </GridLayout>
        </ContentWrapper>
      </GridLayout>
    </Wrapper>
  )
}

export default Doctor
